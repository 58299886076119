@import "assets/stylesheet/variables.scss";

.guided_path_units_modal {
  font-family: "Rubik";
  // height: 90%;
  overflow-y: hidden;
  width: 90%;
  margin: 10px auto;
  @media screen and (max-width: 460px) {
    overflow-y: hidden;
    height: 100%;
    /*::-webkit-scrollbar {
      display: none;
    }*/
  }

  .ant-modal {
    // top: 160px;
    @media screen and (max-width: 460px) {
      width: 100% !important;
      max-width: unset;
      // top: 100px;
      top: 0 !important;
    }
    @media screen and (max-width: 670px) {
      width: 100% !important;
      top: 30px !important;
    }
  }

  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
    //align-items: center;

    &.reverse {
      flex-direction: column;
    }
  }

  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 25px 15px;
    border: none;
    @media screen and (max-width: 460px) {
      border-bottom: 1px solid #828a9d;
    }

    .ant-modal-title {
      color: $theme-text-color-dark;
      font-size: 28px;
      @media screen and (max-width: 460px) {
        font-size: 24px;
      }
    }
  }

  .ant-modal-content {
    background-color: white !important;
    color: #7d7a83;
    padding-bottom: 7px;
    overflow-y: auto;
    height: 100%;
    @media screen and (max-width: 460px) {
      overflow-y: auto;
      height: 100%;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }

    .ant-modal-body {
      padding: 0px 10px 18px 10px !important;
      position: relative;
      height: 63vh;
      overflow: auto;
      @media screen and (max-width: 460px) {
        padding: 10px 0 25px 0 !important;
        height: 100%;
      }
        }
      }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    padding-bottom: 60px;
    display: none;
  }

  .ant-modal-close {
    display: inline;

    .anticon {
      // padding: 9px;
      background: #ececec;
      border-radius: 100px;
      line-height: 0;
    }
  }

  .ant-modal-close-x {
    position: absolute;
    right: 2px;
    top: 5px;
    @media screen and (max-width: 460px) {
      right: 3px;
    }
  }
}

.gp_units_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  .unit_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    border: 1px solid rgb(207, 206, 206);
    box-shadow: none;
    position: relative;
    height: 13rem;

    p {
      text-align: center;
      font-size: 13px;
    }

    .unit_desc {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

  @media (max-width:1400px) {
    grid-template-columns: repeat(3,1fr);
  }

  @media (max-width: 1040px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gp_unit_status_btn {
  border-radius: $theme-border-radius;
  width: fit-content;
  height: 40px;
  padding: 1rem;
  font-size: 1rem;
  line-height: 48.65px;
  background-color: $magenta-color;
  color: white;
  border: 4px solid $magenta-color;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(1px 1px 1px $magenta-color);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 7rem;
  margin: auto;

  p {
    margin-bottom: 0rem;
    font-size: 1rem !important;
  }

  &:disabled {
    color: #fff !important;
    opacity: 0.5;
  }
}

.guided_path_btn_container {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  position: absolute;
  bottom: 10px;
}
