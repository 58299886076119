@import 'assets/stylesheet/variables.scss';

.component-success-modal {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 200;
    cursor: pointer;
  }
  .awesome-card {
    padding: 24px 32px;
    background-color: #1b1b1b;
    border-radius: $theme-border-radius $theme-border-radius;
    margin-bottom: 16px;
    .awesome-text {
      font-size: 36px;
      font-weight: 500;

      @media (max-width: 480px) {
        font-size: 2rem;
      }
    }
    .reward-data {
      display: flex;
      max-height: 114px;
      img {
        background: #212121;
        width: 83px;
        height: 114px;
        object-fit: cover;
        margin-right: 15px;
      }
      .user-data,
      .reward-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .user-data {
        margin-right: 36px;

        max-width: 240px;
        flex-direction: row;
      }
      .coin {
        height: 12px;
        width: auto;
        margin-right: $theme-border-radius;
      }
    }
  }
  .awesome-card-lg {
    padding: 24px 32px;
    background-color: #1b1b1b;
    border-radius: $theme-border-radius $theme-border-radius;
    .awesome-text {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .reward-data {
      display: flex;
      max-height: 114px;
      img {
        background: #212121;
        width: 83px;
        height: 114px;
        object-fit: cover;
        margin-right: 15px;
        border-radius: $theme-border-radius;
      }
      .user-data,
      .reward-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .story-name-container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
      }
      .user-data {
        margin-right: 40px;
        max-width: 300px;
        flex-direction: row;
      }
      .coin {
        height: 12px;
        width: auto;
        margin-right: 4px;
      }
    }
  }
  .recommended-card {
    width: 100%;
    padding: 28px 32px;
    border-radius: $theme-border-radius;
    -webkit-backdrop-filter: blur(16.4px);
    backdrop-filter: blur(16.4px);
    background-color: rgba(151, 238, 253, 0.493);
    &-top {
      display: flex;
      .about-story {
        flex: 0.7;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .next-story-info {
          font-size: 28px;
          font-weight: 500;
          line-height: 1.2;
        }
        .title {
          font-size: 22px;
          font-weight: 500;
        }
      }
      img {
        flex: 0.3;
        height: 160px;
        object-fit: cover;
        background: #eee;
        margin-left: 12px;
      }
    }
    &-bottom {
      display: flex;
      margin-top: 45px;
      justify-content: space-between;
      // .back-btn {
      // }
      .next-btn {
        align-items: center;
        /* justify-content: center; */
        display: flex;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    //FREAD-1010
    .component-success-modal {
      justify-content: unset;
      align-items: unset;
    }
    .ant-btn-lg {
      padding: 0px 5px;
      border-radius: $theme-border-radius;
    }
    .awesome-card {
      padding: 12px 20px;
      .awesome-text {
        font-size: 30px;
      }
      .reward-data {
        flex-direction: column;
        max-height: none;
        .user-data {
          width: 100%;
          max-width: 1000px;
          margin-right: 16px;
          justify-content: flex-start;
          img {
            height: 100px;
          }
        }
      }
      .coin-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
      }
    }
    .recommended-card {
      //FREAD-1010
      //padding: 12px 10px;
      padding: 12px 5px;
      .about-story {
        .next-story-info {
          font-size: 20px;
        }
      }
      img {
        max-width: 120px;
      }
    }
    ///*FREAD-859 */
    //.user-data.fr859 {
    //max-width: 1000px !important;
    //}
  }
  @media (max-width: 480px) {
    .awesome-card-lg {
      padding: 1rem;
      .awesome-text {
        font-size: 2rem;
      }
      .reward-data {
        flex-direction: column;
        max-height: fit-content;
        gap: 1.2rem;

        .user-data {
          margin-right: 0;
          flex-direction: column;
          gap: 1rem;

          img {
            width: 100%;
            margin-right: 0;
          }

          .story-name-container {
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 6px;

            div {
              font-size: 1.1rem !important;
            }
          }
        }

        .coin-data {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          div{
            font-size: 1.1rem !important;
            color: rgb(174, 166, 174);
            display: flex;
            align-items: center;
            gap: 6px;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
///*FREAD-859 */

.user-data.fr859 {
  max-width: 400px !important;
}
.reward-data.fr859 {
  justify-content: space-between !important;
}
