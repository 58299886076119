// Will setup this using some variable for auto build
// but for now you must comment and un-comment accordingly for
// euro or freadom build.

// For freadom
$theme-color: #3e2272;
$theme-btn-color: #ff8a23;
$theme-btn-color-hover: #d8751e;
$theme-btn-color-disabled: #ffdcbd;
$theme-gradient: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
// $magenta-color: #a73e7b;
$magenta-color: #CB5499;
$max-resp-width: "900px";
$theme-border-radius:5px;
$theme-text-color:#524e59;
// $theme-light-color:#f79da0
$theme-light-color:#eeb0d4;
$theme-text-color-dark:#580e3a

// For Euro
// $theme-color: #2ba8e0;
// $theme-btn-color: $theme-color;
// $theme-btn-color-hover: #2693c5;
// $theme-btn-color-disabled: #2ba7e060;
// $theme-gradient: linear-gradient(359deg, #2be0ad, #2ba8e0);
